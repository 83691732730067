<template>
  <div class="w-full h-full pt-2">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />
      <h1 class="text-xl text-left font-extrabold ml-4 mt-3 mr-8">New Role</h1>
    </div>
    <div class="mx-3">
      <Card class="p-5">
        <p class="font-bold text-lg text-darkPurple">Role Details</p>
        <div class="grid grid-cols-12 gap-7 mt-6">
          <div class="col-span-6">
            <c-text
              placeholder="--Enter--"
              variant="w-full"
              label="Role name"
              v-model="role.name"
            />
          </div>
          <div class="col-span-6">
            <c-text
              placeholder="--Enter--"
              label="Role Description"
              v-model="role.description"
              variant="w-full"
            />
          </div>
        </div>
      </Card>
      <Card class="mb-6 p-5">
        <p class="font-bold text-lg text-darkPurple">Access Control</p>
        <div class="text-jet flex">
          <p class="flex-grow">
            Select which apps and associated actions/forms to grant access to
          </p>
          <div class="flex">
            <button @click="openModal = true" class="flex">
              <Icon size="s" icon-name="icon-plus" />
              <p class="ml-3 text-flame font-semibold">Add Apps</p>
            </button>
            <div v-feature-request class="seperator mx-6" />
            <button
              v-feature-request
              @click="openPrivilegesModal = true"
              class="flex"
            >
              <Icon size="s" icon-name="icon-copy" />
              <p class="ml-3 font-semibold text-blueCrayola">
                Import Privileges
              </p>
            </button>
          </div>
        </div>
        <p class="text-romanSilver opacity-50 my-2 text-xs font-semibold">
          APPS
        </p>
        <card v-if="!selectedModules.length" class="p-5">
          <div class="w-full h-auto flex flex-col" style="align-items: center">
            <div
              class="w-5/12 h-auto mt-4 flex"
              style="justify-content: center"
            >
              <icon icon-name="empty_role" class-name="icon-Size mb-2" />
            </div>
            <div
              class="w-6/12 h-auto text-center"
              style="width: 846px; line-height: 19px"
            >
              <p>
                Create a new role and assign app-specific privileges. You can
                always return at any time to edit the privileges of any created
                roles.
              </p>
            </div>
          </div>
        </card>
        <accordion
          v-for="(roleModule, index) in selectedModules"
          :key="index"
          :index="index"
          :open-one-only="true"
          :opened-index="openedIndex"
          v-else
          @open="isAccordionOpen($event)"
          class="accordionContainer"
        >
          <template v-slot:title>
            <div class="flex items-center">
              <div>
                <Icon
                  icon-name="delete"
                  @click.native="deleteModule(roleModule.id)"
                  class="text-desire mr-3 cursor-pointer"
                  size="xsm"
                />
              </div>
              <custom-image :slug="roleModule.slug" class="mr-3" />
              <div class="flex flex-col flex-grow">
                <p class="font-semibold">
                  {{ roleModule.name }}
                </p>
                <p
                  class="
                    text-xs
                    font-semibold
                    text-romanSilver
                    opacity-50
                    uppercase
                  "
                >
                  {{ `• ${roleModule.slug}` }}
                </p>
              </div>
            </div>
          </template>
          <div>
            <Tab
              :tabs="roleModule.submodules.map((item) => item.name)"
              border
              class="overflow-x-auto"
              :active-tab="currentTab"
              @currentTab="handleTabChange($event, roleModule.submodules)"
            />
          </div>
          <Card v-if="currentTab === subModuleName" class="p-2">
            <p
              v-if="currentTab"
              class="text-xs text-romanSilver font-extrabold"
            >
              ACTIONS, PRIVILEGES AND FORMS
            </p>
            <table
              class="w-full"
              v-if="currentTab"
              aria-label="this table shows list of privileges"
            >
              <tbody>
                <tr>
                  <td>
                    <checkbox
                      label="Grant Access to All Privileges in this sub-app"
                      checkbox-size="height:16px; width:16px; margin-top:4px"
                      label-style="color:#333333"
                      value="all"
                      v-model="addMultiple"
                    />
                    <divider class="my-5" border-dash />
                  </td>
                </tr>
                <tr v-for="(item, i) in privileges" :key="i">
                  <td>
                    <checkbox
                      :label="item.name"
                      :value="item.id"
                      checkbox-size="height:16px; width:16px; margin-top:4px"
                      label-style="color:#333333"
                      v-model="selectedPrivileges"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Card>
        </accordion>
      </Card>
      <div class="">
        <div class="flex mt-4">
          <Button
            :disabled="creatingRole"
            @click="createRole"
            class="bg-dynamicBackBtn text-white buttons"
          >
            Save
          </Button>
          <Button @click="cancel" class="buttons"> Cancel </Button>
        </div>
      </div>
    </div>
    <modules
      @close="openModal = false"
      :modules-data="modulesData"
      :selected-modules="selectedModules"
      @selectedModules="handleModules($event)"
      :open-modal="openModal"
      @updateModules="updateModules($event)"
    />

    <import-privileges
      :open-modal="openPrivilegesModal"
      @selectedModule="handleModules($event)"
      @close="openPrivilegesModal = false"
    />
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import fp from "lodash/fp";
import { mapState } from "vuex";
import Card from "@/components/Card";
import Accordion from "@/components/Accordion";
import CustomImage from "@/components/CustomImage";
import Divider from "@/components/divider";
import Checkbox from "@/components/Checkbox";
import Modules from "./addModules";
import ImportPrivileges from "./importPrivileges";

export default {
  name: "Role",
  components: {
    BackButton,
    Card,
    CText,
    Modules,
    Checkbox,
    Tab,
    CustomImage,
    Accordion,
    Divider,
    ImportPrivileges,
  },
  data() {
    return {
      openModal: false,
      openedIndex: 0,
      openPrivilegesModal: false,
      privileges: [],
      selectedModules: [],
      selectedPrivileges: [],
      checking: [],
      creatingRole: false,
      subModuleName: "",
      currentTab: "",
      role: {
        name: "",
        description: "",
      },
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.permission.loading,
      modulesData: (state) => state.permission.modules,
    }),

    addMultiple: {
      get() {
        const newArry = this.privileges.filter((item) =>
          this.selectedPrivileges.includes(item.id)
        );
        return newArry ? newArry.length === this.privileges.length : false;
      },
      set(value) {
        const checked = [];
        if (value) {
          this.privileges.forEach((privilege) => {
            checked.push(privilege.id);
          });
        } else {
          this.privileges.forEach((privilege) => {
            this.selectedPrivileges.splice(
              this.selectedPrivileges.indexOf(privilege.id),
              1
            );
          });
        }
        this.selectedPrivileges = [...this.selectedPrivileges, ...checked];
      },
    },
  },
  methods: {
    reset() {
      this.role = {
        name: "",
        description: "",
      };
      this.privileges = [];
      this.selectedModules = [];
      this.selectedPrivileges = [];
      this.checking = [];
      this.subModuleName = "";
      this.currentTab = "";
    },
    handleModules(modules) {
      this.openModal = false;
      if (this.selectedModules.length) {
        modules.forEach((module) => {
          if (!this.selectedModules.find((item) => item.id === module.id)) {
            this.selectedModules.push(module);
          }
        });
      } else {
        this.selectedModules = modules;
      }
    },
    updateModules(selected){
      this.$store.dispatch("permission/updateModules", selected);
    },
    deleteModule(id) {
      this.selectedModules.forEach((module, index) => {
        if (module.id === id) {
          module.submodules.forEach((subModule) => {
            subModule.privileges.forEach((privilege) => {
              if (this.selectedPrivileges.includes(privilege.id)) {
                this.selectedPrivileges.splice(
                  this.selectedPrivileges.indexOf(privilege.id),
                  1
                );
              }
            });
          });
          this.selectedModules.splice(index, 1);
        }
      });
    },
    isAccordionOpen(isOpen) {
      this.currentTab = "";
      this.openedIndex = isOpen;
      if (isOpen) {
        this.currentTab = "";
      }
    },
    handleTabChange(tabName, subModule) {
      this.currentTab = tabName;
      const getSubModule = fp.compose(
        fp.filter(this.getSelectedItems),
        fp.get("privileges"),
        fp.find("privileges"),
        fp.filter(this.addPrivileges)
      );
      getSubModule(subModule);
    },
    // eslint-disable-next-line consistent-return
    addPrivileges(item) {
      if (item.name === this.currentTab) {
        this.privileges = item.privileges;
        this.subModuleName = item.name;
        return item;
      }
    },
    getSelectedItems(item) {
      if (item.hasPrivilege) {
        this.selectedPrivileges.push(item.id);
      }
    },
    cancel() {
      if (this.$route.params.id) {
        this.$router.push({
          name: "RolesAndPrivileges",
        });
      } else {
        this.reset();
        this.$router.push({
          name: "RolesAndPrivileges",
        });
      }
    },
    // eslint-disable-next-line consistent-return
    createRole() {
      this.creatingRole = true;
      if (this.role.name === "") {
        this.$store.commit("SET_TOAST", {
          text: `Name and Description can't be empty`,
          color: "error",
        });

        return null;
      }
      const payload = {
        appSlug: "humanar",
        orgId: this.$AuthUser.orgId,
        name: this.role.name,
        description: this.role.description,
        rolePrivileges: this.selectedPrivileges.map((resp) => ({
          privilegeId: resp,
        })),
      };

      this.$_NewCreateRoles(payload).then(({ data }) => {
        const message = "Role created successfully"
        this.$toasted.success(data.message || message, { duration: 5000 })
        this.$router.push({ name: "RolesAndPrivileges" })
        this.creatingRole = false
      }).catch((error) => {
        this.$toasted.error(error, { duration: 5000 })
        this.creatingRole = false
        throw new Error(error)
      })
    },
  },
  mounted() {
    this.$store.dispatch("permission/getModules");
  },
};
</script>

<style scoped>
.buttons {
  width: 126px;
  height: 44px;
  border-radius: 5px;
}
.seperator {
  border: 1px solid #878e99;
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
}
.accordionContainer {
  border: 1px solid #878e99;
  box-sizing: border-box;
  border-radius: 5px;
}
td {
  border: none;
  padding: 12px;
  min-height: 43px !important;
}
tr:nth-child(even) {
  background-color: #f7f7ff;
}
</style>